import React, { useRef, useEffect, useState } from 'react'
import '../global.css'
import * as styles from './index.module.css'
import LandingImg from './../images/landing6.jpg'
import styled, { css } from 'styled-components'
import Career from '../components/career/career'
import About from '../components/about/about'
import Lab from '../components/lab/lab'
import Contact from '../components/contact/contact'
import { MdMenu } from 'react-icons/md'
import { IconContext } from 'react-icons'
import { Parallax } from 'react-parallax'
import ReactGA from 'react-ga4'

const HeaderButton = styled.div`
  // padding-top: 1vh;
  // padding-bottom: 1vh;
  font-family: 'Sigmar One';
  -webkit-text-stroke: 2px black;
  ${(props) =>
    props.active &&
    css`
      color: #20506c !important;
    `}
  // ${(props) =>
    props.active &&
    css`
      color: #04aa6d !important;
    `}

  font-size: 2.2vw;
  cursor: pointer;
  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
    `}
  width: auto;
  text-align: center;
  @media (max-width: 992px) {
    font-size: 9vw;
  }
  :hover {
    color: rgb(185 185 185);
  }
`

const scrollBehaviour: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
  inline: 'center',
}

interface Ilink {
  label: string
  ref: React.MutableRefObject<HTMLDivElement | null>
  isActive: boolean
}

// markup
const IndexPage = () => {
  const heroRef = useRef<null | HTMLDivElement>(null)
  const about = useRef<null | HTMLDivElement>(null)
  const career = useRef<null | HTMLDivElement>(null)
  const lab = useRef<null | HTMLDivElement>(null)
  const contacts = useRef<null | HTMLDivElement>(null)
  const [showNavbarBackground, setShowNavbarBackground] = useState(false)
  const [showNavbarMobile, setShowNavbarMobile] = useState(false)

  const [links, setLinks] = useState<Ilink[]>([
    { label: 'About', ref: about, isActive: false },
    { label: 'Career', ref: career, isActive: false },
    { label: 'Lab', ref: lab, isActive: false },
    { label: 'Contacts', ref: contacts, isActive: false },
  ])

  const handleScroll = () => {
    const margin = 100
    links.forEach((link) => (link.isActive = false))
    for (let i = links.length - 1; i >= 0; i--) {
      const link = links[i]
      const offsetY = link.ref.current?.offsetTop || 0
      if (window.scrollY >= offsetY - margin) {
        links[i].isActive = true
        setLinks([...links])
        return
      }
    }
    setLinks([...links])

    setShowNavbarBackground(window.scrollY > 0)

    // for (let link of links.slice().reverse()) {
    //   const offsetY = link.ref.current?.offsetTop || 0
    //   if (window.scrollY >= offsetY) {
    //     link.isActive = true
    //     setLinks(links)
    //     return
    //   }
    // }
  }

  const scrollToRef = (link: Ilink) => {
    setTimeout(() => {
      setShowNavbarMobile(false)
      window.location.hash = link.label.toLowerCase()
      link.ref?.current?.scrollIntoView(scrollBehaviour)
    }, 300)
  }

  const scrollToContacts = () => {
    const contactLink = links.find((link) => link.label === 'Contacts')
    if (!contactLink) return
    scrollToRef(contactLink)
  }

  useEffect(() => {
    ReactGA.initialize('G-M7RMKJNPYH')
    ReactGA.send('pageview')

    const link = links.find((link) => link.label.toLowerCase() === window.location.hash.slice(1))
    if (!!link) {
      scrollToRef(link)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <main className={styles.index}>
      <title>Nuno Tomas - Personal Website</title>
      <div ref={heroRef} className={styles.hero}>
        {!showNavbarMobile && (
          <div className={styles.menuWrapper}>
            <div className={styles.btnMenu} onClick={() => setShowNavbarMobile(true)}>
              <IconContext.Provider value={{ size: '50px', className: 'btnMenu' }}>
                <MdMenu />
              </IconContext.Provider>
            </div>
          </div>
        )}
        <div
          className={`${styles.navbar} 
                         ${!showNavbarBackground ? styles.hideBackground : ''} 
                         ${showNavbarMobile ? styles.showNavbarMobile : ''}`}
        >
          <HeaderButton
            onClick={() => {
              setShowNavbarMobile(false)
              heroRef.current?.scrollIntoView(scrollBehaviour)
            }}
            size='200px'
          >
            <span className={`greyed ${styles.h1Tags}`}>&lt;h1&gt;</span>
            <span> nunotomas.dev </span>
            <span className={`greyed ${styles.h1Tags}`}>&lt;/h1&gt;</span>
          </HeaderButton>
          <div className={styles.links}>
            {links.map((link) => (
              <HeaderButton key={link.label} onClick={() => scrollToRef(link)} active={link.isActive}>
                {link.label}
              </HeaderButton>
            ))}
          </div>
        </div>
        <Parallax className={styles.heroImg} bgImage={LandingImg} strength={800} bgImageStyle={{ objectFit: 'cover !important' }}>
          <div className={styles.landingText}>
            <div>
              <span>plan, </span>
              <span>build,</span>
            </div>
            <div>
              <span>ship, </span>
              <span style={{ fontWeight: 'bold', color: 'white' }}>repeat;</span>
            </div>
          </div>
        </Parallax>
        {/* <img className={styles.heroImg} src={LandingImg} alt="" /> */}
      </div>
      <section ref={about}>
        <About scrollToContacts={scrollToContacts}></About>
      </section>
      <div ref={career}>
        <Career></Career>
      </div>
      <section ref={lab}>
        <Lab scrollToContacts={scrollToContacts}></Lab>
      </section>
      <section ref={contacts}>
        <Contact></Contact>
      </section>
    </main>
  )
}

export default IndexPage
