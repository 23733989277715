// extracted by mini-css-extract-plugin
export var btnMenu = "index-module--btnMenu--3oMLW";
export var h1Tags = "index-module--h1Tags--6PKxf";
export var hero = "index-module--hero--RXViO";
export var heroImg = "index-module--hero-img--hH+M6";
export var hideBackground = "index-module--hideBackground--QGwv3";
export var index = "index-module--index--Hg09G";
export var landingText = "index-module--landing-text--UrlWa";
export var links = "index-module--links--yz6-r";
export var menuWrapper = "index-module--menu-wrapper--25BB7";
export var navbar = "index-module--navbar--uxP48";
export var showNavbarMobile = "index-module--show-navbar-mobile--kuG3q";