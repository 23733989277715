import React from "react"
import * as styles from "./about.module.css"
import MeImg from '../../images/me.jpg'

interface aboutProps {
  scrollToContacts: () => void
}
const about = ({scrollToContacts}: aboutProps) => {

  const navigateExternalUrl = (url: string) => {
    window.open(url, '_blank')?.focus()
  }

  return <div className={styles.aboutSection}>
          <div className={styles.aboutText}>
            <h1>Hey dear human!</h1>
            <div>
              I'm Nuno, a Senior Software Engineer based in Lisbon, with roughly 10 years experience <span>planning</span>, developing and shipping web products.
            </div>
            <br />
            <div>I have a bachelor's degree in Computer Science at <span className={styles.url} onClick={() => navigateExternalUrl('https://tecnico.ulisboa.pt')}>IST</span> and I've worked both on startups and bigger tech companies as a Fullstack / Architect / <span className={styles.underline}>Senior Frontend Engineer</span>.</div>
            <br />
            <div>It is worth mentioning that I do have experience leading teams, managing products and I'm a crypto/ web3 enthusiast.</div>
            
            {/* <h3>My top skills</h3>
            <ul>
              <li>Critical thinker</li>
              <li>Hard worker</li>
              <li>Fast learner</li>
              <li>Team-first mindset</li>
            </ul> */}

            {/* <h3>Working On</h3>
            <ul>
              <li>Put aside perfectionism (YAGNI)</li>
              <li>Avoid overthinking</li>
              <li>Get more organized</li>
            </ul> */}

            <br />
            <div>Want to know more?</div>
            <div>Sure, <span className={styles.url} onClick={scrollToContacts}>let's get in touch!</span></div>

          </div>
          <div className={styles.imgWrapper}>
            <img className={styles.myPhoto} src={MeImg} alt="Nuno's photo" />
          </div>
        </div>

}


export default about