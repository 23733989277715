import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import './career.css'
import { MdOutlineWorkOutline, MdStarBorder, MdOutlineSchool, MdOutlineManageSearch } from 'react-icons/md'
import { GiNewBorn } from 'react-icons/gi'
import BapImg from './../images/bap.jpg'

const highlightIconStyle = { background: '#20506c', color: '#fff' }

const careerEvents = [
  {
    title: 'Senior Frontend Developer',
    subtitle: 'One Click Contractor',
    descs: [
      'Developed a custom design system and implemented a newly designed front end along with some new features for home remodeling estimating software',
    ],
    date: 'Aug 2022 - Present',
    skills: ['React', 'Redux', 'Typescript', 'TailwindCSS', 'Storybook', 'Jest', 'React Native', 'Golang'],
    icon: <MdOutlineWorkOutline />,
    iconStyle: highlightIconStyle,
  },
  {
    title: 'Senior Frontend Developer',
    subtitle: 'Alluder',
    descs: [
      'Developed a video annotation platform that allows users to get customized notifications while watching streaming services like Netflix and HBO',
    ],
    date: 'Mar 2021 - Jul 2022',
    skills: ['React', 'Firebase'],
    icon: <MdOutlineWorkOutline />,
    iconStyle: highlightIconStyle,
  },
  {
    title: 'Senior Software Architect',
    subtitle: 'Buy A Part',
    descs: ['Designed and developed a real estate platform that allowed investors to purchase a part of an apartment'],
    date: 'Sep 2020 - Dec 2021',
    skills: ['Vue.js', 'Node.js', 'Strapi', 'MongoDB', 'AWS'],
    icon: <MdOutlineWorkOutline />,
    iconStyle: highlightIconStyle,
  },
  {
    title: 'Head of Department - Web Products',
    subtitle: 'wTVision',
    descs: [
      'Managed and developed multiple Web Apps for the broadcasting industry: Media Asset Manager, Social Manager, Remote on air graphics, Matchcenter',
      'Led a team of 6 with a great work environment',
    ],
    date: 'Nov 2015 - Jan 2021',
    skills: ['React', 'Vue', 'Angular', 'Typescript', 'Node.js', 'MongoDB', 'Redis', 'AWS', 'Golang', 'RabbitMQ'],
    icon: <MdOutlineWorkOutline />,
    iconStyle: highlightIconStyle,
  },
  {
    title: 'Frontend Developer',
    subtitle: 'wTVision',
    descs: [
      'Developed a mobile app for a quiz show',
      'Script development for realtime sports graphics',
      'Developed a desktop app for sports data collection',
    ],
    date: 'Sep 2014 - Oct 2015',
    skills: ['Angular.js', 'Electron', 'Ionic', 'Python', 'C#', 'Xaml'],
    icon: <MdOutlineWorkOutline />,
    iconStyle: highlightIconStyle,
  },
  {
    title: 'Fullstack Developer',
    subtitle: 'Altice Labs',
    descs: ["Developed yellow pages's Desktop & Web App", "Developed a fleet's management Web App"],
    date: 'Jan 2013 - Aug 2014',
    skills: ['JS', 'JQuery', 'Java', 'ASP.NET', 'Sharepoint', 'SQL'],
    icon: <MdOutlineWorkOutline />,
    iconStyle: highlightIconStyle,
  },
  {
    title: 'Researcher & Frontend Developer',
    subtitle: 'Innovation Makers',
    descs: ['Research activity about NFC tech', 'Developed a user dashboard'],
    date: 'Sep 2011 - Jun 2012',
    skills: ['JS', 'JQuery'],
    icon: <MdOutlineManageSearch />,
    iconStyle: highlightIconStyle,
  },
  {
    title: 'Computer Science',
    subtitle: 'Instituto Superior Técnico',
    descs: ["Bachelor's Degree"],
    date: '2013',
    skills: [],
    icon: <MdOutlineSchool />,
    iconStyle: highlightIconStyle,
  },
]

const career = () => {
  return (
    <div className='timeline-wrapper'>
      <VerticalTimeline className='timeline'>
        {careerEvents.map((careerEvent, idx) => (
          <VerticalTimelineElement key={idx} date={careerEvent.date} iconStyle={careerEvent.iconStyle} icon={careerEvent.icon}>
            <h3 className='vertical-timeline-element-title'>{careerEvent.title}</h3>
            <h4 className='vertical-timeline-element-subtitle'>{careerEvent.subtitle}</h4>
            <div className='descs'>
              {careerEvent.descs.map((desc) => (
                <div key={desc}>{desc}</div>
              ))}
            </div>
            {!!careerEvent.skills.length && (
              <div className='skills'>
                {careerEvent.skills.map((skill) => (
                  <div key={skill} className='skill'>
                    {skill}
                  </div>
                ))}
              </div>
            )}
          </VerticalTimelineElement>
        ))}
        <VerticalTimelineElement iconStyle={{ background: 'rgb(4 86 33)', color: '#fff' }} icon={<GiNewBorn />} />
      </VerticalTimeline>
    </div>
  )
}

export default career
