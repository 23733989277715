import React from 'react'
import * as styles from './lab.module.css'
import DegenImg from '../../images/degen.jpg'
import CrapCostImg from '../../images/crapcost.jpg'
import ClassifiedsImg from '../../images/classifieds.jpg'
import MyWebsiteImg from '../../images/mywebsite.jpg'
import DamImg from '../../images/dam.jpg'
import DamVideo from '../../images/damvideo.mp4'

interface labProps {
  scrollToContacts: () => void
}

const about = ({ scrollToContacts }: labProps) => {
  const navigateExternalUrl = (url: string) => {
    window.open(url, '_blank')?.focus()
  }
  return (
    <div className={styles.labSection}>
      <h2>Personal projects</h2>
      <div>Here's the latest side projects I've developed with the goal of learning/ experimenting new techs and tools.</div>
      {/* <div className='skills'>
            <div className='skill'>WEB3</div>
            <div className='skill'>The Graph API</div>
            <div className='skill'>AWS Lambda</div>
          </div> */}

      <div className={styles.products}>

        <div className={styles.product}>
          {/* <img className={styles.projectImage} src={DamImg} alt='Dam crypto project' /> */}
          <video autoPlay loop muted className={styles.projectImage} src={DamVideo} />
          <div className={styles.description}>
            <div style={{ display: "flex", gap: "8px" }}>
              <h3>DAM.FINANCE</h3>
              <span className={styles.liveProduct}>LIVE</span>
            </div>

            <div>
              <span onClick={() => navigateExternalUrl('https://app.dam.finance/')} className={styles.externalLink}>
                dam.finance
              </span>{' '}
              is a multi-network platform that allows minting d20 stablecoin and bridging it between Ethereum, Moonbeam and Astar networks. {' '}
            </div>
            <div className='flex-column'>
              <div className='greyed'>Research:</div>
              <div className='greyed'>Zustand | Immer | Web3js | LayerZero</div>
            </div>
          </div>
        </div>

        <div className={styles.product}>
          <img className={styles.projectImage} src={DegenImg} alt='Degen crypto project' />
          <div className={styles.description}>
            {/* <span className={styles.live}product'>LIVE</span> */}
            <h3>DEGEN LAND</h3>
            <div>
              <span>degen.land</span> is a crypto community gathering place, with useful tools for all types of crypto enthusiasts - degens.
            </div>
            <div className='flex-column'>
              <div className='greyed'>Research</div>
              <div className='greyed'>Ethers.js | The Graph API | AWS Lambda</div>
            </div>
          </div>
        </div>

        <div className={styles.product}>
          <img className={styles.projectImage} src={MyWebsiteImg} alt='Personal website' />
          <div className={styles.description}>
            <div style={{ display: "flex", gap: "8px" }}>
              <h3>PERSONAL WEBSITE</h3>
              <span className={styles.liveProduct}>LIVE</span>
            </div>
            <div>
              <span onClick={() => navigateExternalUrl('https://nunotomas.dev')} className={styles.externalLink}>
                nunotomas.dev
              </span>{' '}
              is an attempt to let you know more about me. Do you have a challenge?{' '}
              <span onClick={scrollToContacts} className={styles.contactLink}>
                Let me know!
              </span>
            </div>
            <div className='flex-column'>
              <div className='greyed'>Research:</div>
              <div className='greyed'>GatsbyJS | Gatsby Cloud | EmailJS</div>
            </div>
          </div>
        </div>

        <div className={styles.product}>
          <img className={styles.projectImage} src={ClassifiedsImg} alt='Classifieds project' />
          <div className={styles.description}>
            <h3>CLASSIFIEDS</h3>
            <div>Classifieds is a platform that aims to create a safer place for people wanting to buy used goods.</div>
            <div className='flex-column'>
              <div className='greyed'>Research:</div>
              <div className='greyed'>Quasar Framework | Strapi CMS | Git Submodules</div>
            </div>
          </div>
        </div>

        <div className={styles.product}>
          <img className={styles.projectImage} src={CrapCostImg} alt='Crapcost project' />
          <div className={styles.description}>
            <h3>CRAP COST</h3>
            <div>Crapcost was a simple calculator that aimed to raise awareness to the high level of consumerism. <span>Users: dozens</span></div>
            <div className='flex-column'>
              <div className='greyed'>Research:</div>
              <div className='greyed'>PWA | Stripe | SN sharing</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default about
