import React, { useEffect, useRef, useState } from "react"
import "./contact.css"
import MyImage from '../../images/me.jpg'
import emailjs from '@emailjs/browser'
import classnames from 'classnames'
// import { ToastContainer, toast } from 'react-toastify'
import { SiLinkedin } from 'react-icons/si'
import { MdEmail } from 'react-icons/md'
import { BiWorld } from 'react-icons/bi'
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';

interface FormType {
  name?: string
  email?: string
  contactType?: string
  salary?: string
  desc?: string
}

const contact = () => {
  const [salaryBudget, setSalaryBudget] = useState<number>(100)
  const [formData, setFormData] = useState<FormType>({ salary: '100' })
  // const [workWeekPossible, setWorkWeekPossible] = useState<string>('no')
  const form = useRef();

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [event.target.name]: event.target.value})
  }

  // const handleWoorkWeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setWorkWeekPossible(e.target.value);
  // }

  const handleSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSalaryBudget(+e.target.value);
  }

  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault()
    await toast.promise(
      emailjs.sendForm('service_cnrox2v', 'template_d5a2jvj', form.current, 'BfKa9rt4wwOBc7Xcp'),
      {
        loading: 'Saving...',
        success: <div><b>Thank you for your contact!</b><div>Will get back to you soon.</div></div>,
        error: <b>Error sending message.</b>
      }, 
      {
        success: {
          duration: 5000,
          icon: <img style={{ borderRadius: '50%', width: '55px' }} src={MyImage}></img>,
        },
        style: { border: '1px solid white', padding: '16px', color: 'black', borderRadius: '0', minHeight: '55px', minWidth: '300px'}
    })
    setFormData({ name: '', email: '', contactType: '', salary: '100' })
  }

  return <div id='contact-section'>
          <Toaster />
          <div className='contact-info'>
            <h2>Let's get in touch</h2>

            <div className='contact'>
              <div className='contact-icon'>
                <BiWorld></BiWorld>
              </div>
              <div>nunotomas.dev</div>
            </div>


            <div className='contact'>
              <div className='contact-icon'>
                <MdEmail></MdEmail>
              </div>
              <div>nuno.tomas11@gmail.com</div>
            </div>
            
            <div className='contact'>
              <div className='contact-icon'>
                <SiLinkedin></SiLinkedin>
              </div>
              <div>linkedin.com/in/nunotomasdev</div>
            </div>

            { formData.contactType === 'hireme' && 
              <div className={classnames('smiley', {
                  happy: Number(formData.salary) >= 110,
                  normal: Number(formData.salary) < 110 && Number(formData.salary) >= 80,
                  angry: Number(formData.salary) < 80,
                })}>
                <div className="eyes">
                  <div className="eye"></div>
                  <div className="eye"></div>
                </div>
                <div className="mouth"></div>
              </div>
            }
          </div>
          <div className='contact-form'>
            <h2>Get in touch</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div>
                <div>Name</div>
                <input type="text" name="name" onChange={handleFormChange} value={formData.name} required autoComplete='name'/>
              </div>
              <div>
                <div>Email</div>
                <input type="email" name="email" onChange={handleFormChange} value={formData.email} required autoComplete='email'/>
              </div>
              <div></div>
              <div className='flex-row gap32'>
                <div className='flex-row gap4'>
                  <input id="hireme" type="radio" name="contactType" value="hireme" checked={formData.contactType === 'hireme'} onChange={handleFormChange} required></input>
                  <label htmlFor='hireme'>Hire me</label>
                </div>
                <div className='flex-row gap4'>
                  <input id="sayhi" type="radio" name="contactType" value="sayhi" checked={formData.contactType === 'sayhi'} onChange={handleFormChange}></input>
                  <label htmlFor='sayhi'>Say hi</label>
                </div>
              </div>
              <div>
                { formData.contactType === 'sayhi' && 
                  (
                    <textarea name="desc" cols={40} rows={10} required></textarea>
                  )
                }
              </div>
              { formData.contactType === 'hireme' && 
                (
                  <>
                    <div className='salary-section'>
                      <div>Annual Salary (budget)</div>
                      <div className='labeled-range'>
                        <input type="range" min={10} max={250} step={10} defaultValue="100" className="slider" id="myRange" name='salary' onChange={handleFormChange}></input>
                        <div className='label'>${formData.salary}k{formData.salary === '250' ? '+' : ''}</div>
                      </div>
                    </div>

                    {/* <div>
                      <div>Is a less than 40h work week possible? (eg: 30h work week)</div>
                      <input id="workweekyes" type="radio" name="workweek-type" value="yes" onChange={handleWoorkWeekChange}></input>
                      <label htmlFor='workweekyes'>Yes</label>
                      <input id="workweekmaybe" type="radio" name="workweek-type" value="maybe" onChange={handleWoorkWeekChange}></input>
                      <label htmlFor='workweekmaybe'>Maybe</label>
                      <input id="workweekno" type="radio" name="workweek-type" value="no" onChange={handleWoorkWeekChange}></input>
                      <label htmlFor='workweekno'>NO!</label>
                    </div> */}
                    <div>Job description</div>
                    <textarea name="desc" cols={40} rows={10} required></textarea>
                  </>
                )
              }
              <input className='cursor-pointer' type="submit" value="Send" />
            </form>
          </div>
        </div>
}


export default contact